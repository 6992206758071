import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import Vimeo from '@u-wave/react-vimeo';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Player(props) {
    let history = useHistory();

    // console.log(props.location.state);

    let video = props.location.state.dataVideo.video;

    console.log(video);

    const videoContainer = {
        height: '100vh'
    };

    const videoStyle = {
        height: '100vh'
    };
    return (
        <div style={videoContainer}>
            <Row className="mt-3">
                <Col className="text-center">
                    <span className="display-4">
                        {video.title}
                    </span>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Vimeo
                        video={video.vimeoid}
                        style={videoStyle}
                        width="100%"
                        height="100%"
                        autoplay
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <p>
                        {video.description}
                    </p>
                </Col>
                <Col md={5}>
                    Autore: <strong>{video.microlearningauthor.first_name} {video.microlearningauthor.last_name}</strong>
                    <p>
                        <img src={"https://wetell.it/" + video.microlearningauthor.avatar} className="authorAvatar text-right"/>
                        <small>{video.microlearningauthor.bio}</small>
                    </p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Button onClick={() => history.push('/')} variant="outline-primary">Ritorna all'elenco</Button>
                </Col>
            </Row>


        </div>
    );

}

export default Player;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';


import 'bootstrap/dist/css/bootstrap.min.css';

const VIDEOS = [
  {
    id: 1,
    title: "Titolo Video Uno",
    vimeoid: "375929415",
    thumb: "https://dummyimage.com/600x400/000/fff",
    author: "Dario Barilà",
    description: "Lorem ipsum dolor sit et consetuca"
  },
  {
    id: 2,
    title: "Titolo Video Due",
    vimeoid: "375929257",
    thumb: "https://dummyimage.com/600x400/000/fff",
    author: "Dario Barilà",
    description: "Lorem ipsum dolor sit et consetuca"
  },
  {
    id: 3,
    title: "Titolo Video Tre",
    vimeoid: "375926070",
    thumb: "https://dummyimage.com/600x400/000/fff",
    author: "Dario Barilà",
    description: "Lorem ipsum dolor sit et consetuca"
  },
];

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App videos={VIDEOS} />
    </Router>
  </React.StrictMode>
  ,
  document.getElementById('rootMicroLearning')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


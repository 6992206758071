import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button'
import { useHistory } from "react-router-dom";
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import TextTruncate from 'react-text-truncate';

function Videothumb(props) {
    let history = useHistory();

    const thumbStyle = {
        Width: '100px'
    };

    function showVideo(qualevideo) {
        history.push('/player', { dataVideo: props });

    }

    console.log('thumb', props);

    return (
        <Col md={4} xs={12}>
            <Card>
                <Card.Img variant="top" src={props.video.thumbnail} style={thumbStyle} />
                <Card.Body>
                    <Card.Title>{props.video.short_title}</Card.Title>
                    <Card.Text>
                        <Row>
                            <Col className="text-center">
                                <img className="authorAvatar shadow rounded" src={"https://wetell.it/" + props.video.microlearningauthor.avatar} />
                                <small>{props.video.microlearningauthor.first_name} {props.video.microlearningauthor.last_name}</small>
                            </Col>
                            <Col className="text-left">                               
                                <TextTruncate 
                                    line={7}
                                    element="span"
                                    truncateText="…"
                                    text= {props.video.description}
                                    
                                />
                            </Col>
                        </Row>

                    </Card.Text>
                </Card.Body>
                <Card.Footer>
                    <Button onClick={showVideo} variant="outline-primary">Guarda</Button>
                </Card.Footer>
            </Card>
        </Col>

    );

}

export default Videothumb;
import React, { useState, useEffect } from 'react';
import './App.css';
import Videolist from './components/Videolist';
import Container from 'react-bootstrap/Container';
import { Route, useHistory } from 'react-router-dom';
import Player from './components/Player';

const videosApi = "https://cifa.academy/api/microlearning";

function App(props) {
  const [elencoVideo, setData] = useState([]);

  const fetchVideos = async () => {
    fetch(videosApi)
      .then(response => response.json())
      .then(data => {
        //elencoVideo = data.data;
        console.log("elencoVideo", elencoVideo)
        setData(data.data);
      });
  };

  
  useEffect(() => {
    fetchVideos();
  }, []);



  return (
    <Container>
      <Route exact path="/" render={() => <Videolist videos={elencoVideo} />} ></Route>
      <Route path="/player" component={Player}></Route>
    </Container>


  );
}

export default App;

import Videothumb from './Videothumb'
import CardDeck from 'react-bootstrap/CardDeck'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function Videolist(props) {

    console.log('props videolist',props);

    let lista = props.videos.map((videoData) => {
        console.log("map video", videoData);
        return <Videothumb key={videoData.id} video={videoData} />
    });

    return (
      <Row>
          {lista}
      </Row>
    );
}

export default Videolist;